import mixitup from "mixitup";

const blockServiceScrollReveal = () => {
  ScrollReveal().reveal(".block-services h2", { delay: 500, duration: 1000 }); // eslint-disable-line
  ScrollReveal().reveal(".block-services-list", { delay: 800, duration: 1000 }); // eslint-disable-line
};

const blockServiceSwiper = () => {
  const blocks = document.querySelectorAll(".block-services-list");

  if (blocks.length === 0) {
    return;
  }

  [...blocks].forEach((item) => {
    const swiper = new Swiper(item, { // eslint-disable-line
      // Optional parameters
      autoplay: {
        delay: 2000,
      },
      lazy: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      freeMode: true,
      rewind: true,
      passiveListeners: true,
      watchOverflow: true,

      breakpoints: {
        280: {
          slidesPerView: "auto",
          spaceBetween: 16,
          loop: false,
        },
        768: {
          centeredSlides: false,
          slidesPerView: 3,
          spaceBetween: 30,
          loop: true,
        },
        1280: {
          centeredSlides: false,
          slidesPerView: 4,
          spaceBetween: 30,
          loop: true,
        },
      },
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  });
};

document.addEventListener("DOMContentLoaded", () => {
  blockServiceScrollReveal();

  blockServiceSwiper();

  const mixerEl = document.querySelectorAll(".services-wrapper");
  if (mixerEl.length > 0) {
    const mixer = mixitup(".services-wrapper", {
      selectors: {
        target: ".service",
      },
      animation: {
        duration: 300,
      },
    });

    mixer();
  }
});
